<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="tgju-widgets-block col-12 col-md-12 profile-news">
            <div class="tgju-widget light has-icon t-mb-2"  data-target="profile-tour-step-11">
                <h2 class="tgju-widget-title dot m-0">
                گزارشات  {{ market_news.info.title }}
                </h2>

                <section class="faq-accordion" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/FAQPage">
                    <div v-for="(item, index) in content_market" :key="index" @click="toggleMe" class="card" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
                        <h3 class="title-faq" itemprop="name"><i class="fa fa-plus-square-o"></i> {{ item.title }}</h3>
                        <div class="answer-faq" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                            <div itemprop="text" v-html="item.body"></div>
                        </div>
                    </div>
                </section>
                <div class="stocks-block-icon"><i class="fa fa-list"></i></div>
            </div>
        </div>
    </slot>
</div>
</template>

<script>
import $ from "jquery";

export default {
    name: "MarketNewsComponent",
    data() {
        return {
            widgetLoad: true,
            market_news: [],
            content_market: [],
        }
    },
    mounted() {
        this.getProfileNews();
    },
    methods: {
        getProfileNews() {
            this.$helpers
                .makeRequest("GET", `/market/profile-news/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_news = api_response.data.response.detail.response;
                        this.content_market = api_response.data.response.detail.content_market.response;
                        this.$parent.title = this.market_news.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
        // اضافه کردن تغییر وضعیت نمایش
        toggleMe(event) {
            $(event.target).parent().toggleClass('active');
        },
    },
}
</script>